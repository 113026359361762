import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/prismic/link-resolver';
import componentResolver from '../utils/prismic/component-resolver';
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby'
import styled from 'styled-components';

import { hasRichText, renderRich } from '../utils/common/rich-text';
import Layout from '../components/layout/layout';

import Wrapper from '../components/layout/wrapper';
import Button from '../components/snippets/button';

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 230px 0;

    @media screen and (min-width: 768px) {
        padding: 230px 40px;
        margin: 0 auto;
        max-width: 740px;
    }
`

const Heading = styled.h1`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 104px;
    margin: 0 0 24px;
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 48px;

    @media screen and (min-width: 768px) {
        margin: 0 0 60px;
    }
    
    * {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
`
// TODO: Fix typescript;
const NotFoundPage = (props: any) => {
    const pathName = props?.location?.pathname;
    const matchData = pathName?.match(/^\/([^-]+-[^-\/]+)/m);
    const currentLang = matchData ? matchData[1] : 'de-eu';

    const data = useStaticQuery(graphql`
        query {
            allPrismic404 {
                nodes {
                    lang
                    data {
                        text {
                            richText
                        }
                    }
                }
            }
            allPrismicHeader {
                nodes {
                    _previewable
                    lang
                    data {
                        announcement {
                            richText
                        }
                        announcement_link {
                            url
                            target
                        }
                        menu_items {
                            link {
                                url
                                id
                            }
                            link_text
                            mega_menu_items {
                                document {
                                    ... on PrismicHeaderMenuItem {
                                        id
                                        data {
                                            button_text
                                            button_url {
                                                url
                                            }
                                            description {
                                                richText
                                            }
                                            title {
                                                richText
                                            }
                                            item {
                                                item_description {
                                                    richText
                                                }
                                                item_title {
                                                    richText
                                                }
                                                mega_menu_image {
                                                    alt
                                                    gatsbyImageData
                                                }
                                                link {
                                                    url
                                                    target
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allPrismicFooter {
                nodes {
                    _previewable
                    lang
                    data {
                        disclaimer {
                            richText
                        }
                        footer_columns {
                            footer_column {
                                document {
                                    ... on PrismicLinkList {
                                        id
                                        type
                                        data {
                                            link_text
                                            link {
                                                url
                                            }
                                            sub_links {
                                                sub_link_text
                                                sub_link {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                    ... on PrismicSubscriptionForm {
                                        id
                                        type
                                        data {
                                            button_text {
                                                richText
                                            }
                                            field_placeholder
                                            optin_error
                                            email_format_error
                                            list
                                            optin {
                                                richText
                                            }
                                            tags {
                                                tag
                                            }
                                            text {
                                                richText
                                            }
                                        }
                                    }
                                    ... on PrismicSocialList {
                                        id
                                        type
                                        data {
                                            link {
                                                url
                                            }
                                            link_text
                                            sub_links {
                                                sub_link_icon
                                                sub_link {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        icons {
                            icon
                            title {
                                richText
                            }
                        }
                        social_links {
                            social_icon
                            social_link {
                                url
                                target
                            }
                        }
                    }
                }
            }
            allPrismicSettings {
                nodes {
                    _previewable
                    lang
                    data {
                        empty_cart_flair {
                            gatsbyImageData
                            alt
                        }
                        link_to_contact {
                            url
                        }
                        link_to_products {
                            url
                        }
                        link_to_help {
                            url
                        }
                        link_to_magazine_overview {
                            url
                        }
                        link_to_thank_you {
                            url
                        }
                        link_to_home_default {
                            url
                        }
                    }
                }
            }
            allPrismicGeneralTranslations {
                nodes {
                    lang
                    data {
                        add_subscription
                        add_to_cart
                        back_to_top
                        compare_brushes
                        compare_plans
                        go_to_cart
                        from
                        heading
                        in_stock
                        loading
                        select_brush
                        select_color
                        select_plan
                        shipping_line
                        total
                        shipping_message
                        free_month_recurring
                        free_month
                        brush
                        was_this_helpful
                        back_to_home
                    }
                    lang
                }
            }
        }
    `);

    const pageData = data.allPrismic404?.nodes?.find(({ lang }: { lang: string }) => lang && lang === currentLang);
    const translationData = data.allPrismicGeneralTranslations?.nodes?.find(({ lang }: { lang: string }) => lang && lang === currentLang);
    const settingsData = data.allPrismicSettings?.nodes?.find(({ lang }: { lang: string }) => lang && lang === currentLang);
    const headerData = data.allPrismicHeader?.nodes?.find(({ lang }: { lang: string }) => lang && lang === currentLang);
    const footerData = data.allPrismicFooter?.nodes?.find(({ lang }: { lang: string }) => lang && lang === currentLang);

    const [textHasRichText, textRichText] = hasRichText(pageData?.data?.text?.richText);

    // TODO: Fix layout missing bundles
    return (
        <Layout
            footerData={footerData?.data as Queries.PrismicFooterDataType}
            headerData={headerData?.data as Queries.PrismicHeaderDataType}
            location={props.location}
            audience={'kids'}
            lang={currentLang}
        >
            <Wrapper>
                <Section>
                    <Heading>404</Heading>
                    {textHasRichText && (
                        <Text>
                            {renderRich(textRichText)}
                        </Text>
                    )}
                    <Button url={settingsData?.data?.link_to_home_default?.url || ''} buttonStyle={'solid'}>
                        {translationData?.data?.back_to_home}
                    </Button>
                </Section>
            </Wrapper>
        </Layout>
    )
}

export default withPrismicUnpublishedPreview(
    NotFoundPage,
    [{
        repositoryName: 'playbrush',
        linkResolver: linkResolver,
        componentResolver: componentResolver,
    }]
)